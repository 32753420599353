import { useLocation, useNavigate } from '@remix-run/react'
import { useState } from 'react'
import ImageComponent from '#app/components/common/image-component'
import PlentyBody from '#app/components/typography/plenty-body.tsx'
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from '#app/components/ui/carousel.tsx'
import { cn } from '#app/utils/misc'
import { type ClerkProduct } from '#types/product'
import BottomLabels from '../labels/bottom-labels'
import NewLabel from '../labels/new'
import { ClerkPriceTag } from './clerk-price-tag'
import ColorVariants from './color-variants'

interface ProductCardProps {
	product: any
	variants?: any[]
	isSlider?: boolean
	lazyLoad?: boolean
	priority?: 'auto' | 'low' | 'high' | undefined
	width?: string
	className?: string
}

const ClerkProductCard = ({
	product,
	variants = [],
	isSlider = false,
	priority = 'auto',
	lazyLoad,
	width,
	className,
}: ProductCardProps) => {
	const [isHovered, setIsHovered] = useState(false)
	const [showVariants, setShowVariants] = useState(false)
	const cardClassNames = cn(
		'flex cursor-pointer flex-col gap-2 flex-grow',
		isSlider ? 'max-w-[285px] min-h-[265px] md:min-h-[416px]' : '',
		width ? `min-w-[${width}]` : '',
		className,
	)
	const location = useLocation()
	const navigate = useNavigate()

	const handleClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()
	}

	const handleClickOnVariant = (variant: ClerkProduct) => {
		navigate(`/product/${variant.style_id}`, {
			state: {
				previousType: location.pathname.includes('group') ? 'group' : 'product',
				previousURL: location.pathname.split('/')[2] || null,
				amplitudePageSource: location.pathname,
			},
		})
	}

	return (
		<div className={cardClassNames} key={`${product.id}`}>
			<div
				className=" group/product relative flex h-fit"
				onMouseEnter={() => {
					setIsHovered(true)
					setShowVariants(true)
				}}
				onMouseLeave={() => {
					setIsHovered(false)
					setShowVariants(false)
				}}
			>
				<div className="special-wrap w-full">
					<ImageComponent
						size="original"
						src={product.hover_image}
						alt={product.name}
						className={cn(
							`-mb-[22px] md:-mb-0 h-full rounded-lg object-cover  transition duration-300 ease-in-out ${
								isSlider ? ' min-h-[265px] md:min-h-[416px]' : ''
							}`,
						)}
						style={{
							aspectRatio: '1/1.45',
							backgroundColor: '#ececec',
						}}
						loading={lazyLoad ? 'lazy' : 'eager'}
						fetchpriority={priority}
					/>
					<ImageComponent
						size="original"
						src={product.image}
						alt={product.name}
						className={cn(
							`-mb-[22px] md:-mb-0 h-full rounded-lg object-cover   transition duration-300 ease-in-out ${
								isSlider ? 'min-h-[265px] md:min-h-[416px]' : ''
							}`,
							'opacity-100 group-hover/product:opacity-0',
						)}
						style={{
							aspectRatio: '1/1.45',
							backgroundColor: '#ececec',
						}}
						loading={lazyLoad ? 'lazy' : 'eager'}
						fetchpriority={priority}
					/>
				</div>
				{/* Labels  */}
				<div>
					<NewLabel product={product} />

					<BottomLabels
						product={product}
						hidden={isHovered && variants.length > 0 && showVariants}
					/>
				</div>
				{variants.length > 0 && showVariants && (
					<div className="absolute bottom-0 hidden w-full md:flex">
						<Carousel className="relative w-full bg-white-20p ">
							<CarouselContent className="min-gap-2 relative m-0 py-2 pl-2 ">
								{variants.map(variant => (
									<CarouselItem
										key={variant.id}
										className="basis-[20%] px-[4px] pl-0"
									>
										<div
											onClick={e => {
												e.preventDefault()
												handleClickOnVariant(variant)
											}}
											className="cursor-pointer rounded-lg object-cover"
										>
											<ImageComponent
												size="thumbnail"
												src={variant.image}
												alt={variant.name}
												className="rounded-lg border-2 border-white bg-white object-cover transition duration-300 ease-in-out hover:border-mint-green-50"
											/>
										</div>
									</CarouselItem>
								))}
							</CarouselContent>
							<div
								className="absolute -left-10 top-3/4 z-20 -translate-y-1/2 transform"
								onClick={handleClick}
							>
								<CarouselPrevious
									size={'default'}
									className="relative z-20 rounded-full px-[8px] py-[8px]"
								/>
							</div>
							<div
								className="absolute -right-10 top-3/4 z-20 -translate-y-1/2 transform"
								onClick={handleClick}
							>
								<CarouselNext
									size={'default'}
									className="relative z-20 rounded-full px-[8px] py-[8px]"
								/>
							</div>
						</Carousel>
					</div>
				)}
			</div>
			<div>
				<PlentyBody size="sm" className="text-beige-100">
					{product.brand}
				</PlentyBody>
				<PlentyBody size="sm" className="">
					{product.name}
				</PlentyBody>
				<ClerkPriceTag product={product} />
				<div className="md:hidden">{ColorVariants(variants)}</div>{' '}
			</div>
		</div>
	)
}

export default ClerkProductCard
